<template>
  <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <div class="title">修改物料需要到达日期</div>
    <a-form-model-item :label="form.cmMName">
      <!-- v-model="form.cdExpectStartTime"  :value="moment(1721183257000)"-->
      <a-date-picker
        v-model="StartDate"
        :disabled-date="disabledStartDate"
        format="YYYY-MM-DD"
        placeholder="起始日期"
        @openChange="handleStartOpenChange"
        @change="onChangeDateStart"
      />
      <a-date-picker
        v-model="EndDate"
        :disabled-date="disabledEndDate"
        format="YYYY-MM-DD"
        placeholder="截止日期"
        :open="endOpen"
        @openChange="handleEndOpenChange"
        @change="onChangeDateEnd"
      />
    </a-form-model-item>
    <a-form-model-item label="变更供应商">
      <a-select v-model="form.cmSId" class="search-expand-value">
        <a-select-option
          v-for="opt in GetSelectCompanies"
          :key="opt.msSId"
          :value="opt.msSId"
          >{{ opt.tsupplier.sname }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <!-- <a-button type="primary" @click="onSubmit"> 保存 </a-button> -->
      <a-button type="primary" @click="onSubmit('dynamicValidateForm')">
        保存
      </a-button>
      <a-button style="margin-left: 10px"> 取消 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import moment from "moment";
import { GetSelectByIdList } from "@/api/apiJF/material";
import { GetProjectList } from "@/api/apiJF/template";
export default {
  name: "ScheduleMaterialsEdit",
  props: {
    record: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      moment,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        cmMId: "",
        cmMName: "",
        cmMUnit: "",
        cmQuantity: "",
        cmEarlyReachTime: "",
        cmLateReachTime: "",
        cmMState: "1",
        cmSId: "", //供应商id
      },
      StartDate: "",
      EndDate: "",
      endOpen: false,
      GetSelectCompanies: [],
      editForm: {},
      ProjectList: [], //施工项列表
    };
  },
  created() {
    console.log("create--Materials-record", this.record, this.type);
    if (this.type == "edit") {
      for (let el in this.record) {
        this.form[el] = this.record[el];
      }
    }

    this.StartDate = this.form.cmEarlyReachTime
      ? this.$Format(this.form.cmEarlyReachTime)
      : "";
    this.EndDate = this.form.cmLateReachTime
      ? this.$Format(this.form.cmLateReachTime)
      : "";
    this.form.cmMState = "1";
    // console.log("this.form", this.form);
    // 获取供应商
    this.GetSelectByIdList(this.record.cmMId);
  },
  methods: {
    onSubmit() {
      this.editForm = this.form;
      let cmEarlyReachTime = this.form.cmEarlyReachTime;
      let cmLateReachTime = this.form.cmLateReachTime;
      this.GetSelectCompanies.forEach((el) => {
        if (el.msSId == this.form.cmSId) {
          this.editForm.cmSName = el.tsupplier.sname;
          this.editForm.cmSId = el.msSId;
          this.editForm.cmMPreTime = el.msPreTime;
          this.editForm.cmMdeLiTime = el.msDeliTime;
          this.editForm.cmEarlyOrderTime =
            cmEarlyReachTime - el.msPreTime * 3600 - el.msDeliTime * 3600;
          this.editForm.cmLateOrderTime =
            cmLateReachTime - el.msPreTime * 3600 - el.msDeliTime * 3600;
          this.editForm.cmEarlySendTime =
            cmEarlyReachTime - el.msDeliTime * 3600;
          this.editForm.cmLateSendTime = cmLateReachTime - el.msDeliTime * 3600;
          console.log("msPreTime", el.msPreTime);
          console.log("msDeliTime", el.msDeliTime);
        }
      });
      console.log("cmEarlyReachTime", this.$Format(cmEarlyReachTime));

      console.log(
        "cmEarlyOrderTime",
        this.$Format(this.editForm.cmEarlyOrderTime)
      );
      console.log(
        "cmEarlySendTime",
        this.$Format(this.editForm.cmEarlySendTime)
      );

      console.log("editForm", this.editForm);
      this.$emit("tabledataChange", this.editForm);
    },

    // 起止日期的选择和禁止选择
    disabledStartDate(startValue) {
      const endValue = this.EndDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.StartDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 关闭
    onClose() {
      this.$emit("onClose");
    },

    onChangeDateStart(date, dateString) {
      let start = Date.parse(date) / 1000;
      this.form.cmEarlyReachTime = start;
    },
    onChangeDateEnd(date, dateString) {
      let end = Date.parse(date) / 1000;
      this.form.cmLateReachTime = end;
    },
    // 获取供应商
    GetSelectByIdList(mId) {
      let queryParam = {
        mId: mId,
      };
      let orderParam = ["mId"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetSelectByIdList(params).then((res) => {
        this.GetSelectCompanies = res.data.tmaterialSuppliers;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin: 20px 0;
  font-weight: 800;
  font-size: 16px;
}

.title1 {
  margin-top: 50px;
}
/deep/.ant-row {
  display: flex;
  width: 79%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
</style>
