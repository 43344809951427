<template>
  <div>
    <!-- 基本信息 -->
    <div class="basicInfo">
      <div class="infoTit">
        <div>基本信息</div>
      </div>
      <div class="basicInfo_edit">
        <a-form-model layout="inline" :model="form">
          <a-form-model-item label="所属公司">
            <a-select
              v-model="form.wsBcId"
              placeholder="请选择"
              style="width: 150px"
              :disabled="$route.query.type != 'add'"
            >
              <a-select-option
                v-for="item in GetCompanyList"
                :key="item.bc_id"
                :value="item.bc_id"
              >
                {{ item.bc_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关联报价">
            <a-select
              v-model="form.wsQId"
              placeholder="请选择"
              style="width: 150px"
              :disabled="$route.query.type != 'add'"
              @change="handleChangewsQId"
            >
              <a-select-option
                v-for="item in QuotationList"
                :key="item.qid"
                :value="item.qid"
              >
                {{ item.qname }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="材料优先选择顺序">
            <a-select
              v-model="form.wsMSelector"
              placeholder="请选择"
              style="width: 200px"
              :disabled="viewShow"
              mode="multiple"
              @change="handleChangewsMSelector"
            >
              <a-select-option
                v-for="item in wsMSelectorList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="预计开始时间">
            <a-date-picker
              v-model="form.wsExpectTime"
              format="YYYY-MM-DD"
              :value-format="valueFormat"
              placeholder="预计开始时间"
              :disabled="viewShow"
              @change="handleChangewsExpectTime"
            />
            <!--
               :disabled-date="disabledStartDate"
              @openChange="handleStartOpenChange"
              @change="onChangeDateStart" -->
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div
      v-if="
        JSON.parse($route.query.record).wsLatest == 0 &&
        $route.query.type == 'edit'
      "
    >
      <div v-if="isShowwsLatestBtn" class="wsLatestinfo">
        <a-button type="primary" class="wsLatestBtn" @click="isChangeInfo()"
          >同步报价信息</a-button
        >
        <a-alert message="此工地报价信息已修改，是否同步" banner />
      </div>
      <div v-else class="wsLatestinfo">
        <a-button type="primary" class="wsLatestBtn">已同步报价信息</a-button>
        <a-alert message="同步成功" banner type="success" />
      </div>
    </div>
    <!-- 详细信息 -->
    <div>
      <div class="infoTit">详细信息</div>
      <div class="info_inner">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="施工排期">
            <div class="tableHeight">
              <a-table
                ref="table"
                size="small"
                :rowKey="
                  (record, index) => {
                    return record.cdId
                      ? record.cdId + '-' + record.cpId
                      : record.cpId;
                  }
                "
                :columns="columns"
                :data-source="tabledata"
                :scroll="{ x: 1400, y: 400 }"
                :defaultExpandAllRows="true"
                v-if="tableShow"
                :pagination="false"
              >
                <span slot="cdBcId" slot-scope="text">
                  <div>{{ text ? CompanyListResult(text) : "" }}</div>
                </span>
                <span slot="cpWpId" slot-scope="text">
                  <div>
                    {{ text ? WorkProceResult(text) : "" }}
                  </div>
                </span>
                <span slot="cdPId" slot-scope="text">
                  <div>
                    {{
                      ProjectListResult(text) ? ProjectListResult(text) : text
                    }}
                  </div>
                </span>
                <span slot="cdExpectStartTime" slot-scope="text, record">
                  <div v-if="text && text != null">
                    {{ $Format(text).slice(0, 10) }} -
                    {{ $Format(record.cdExpectEndTime).slice(0, 10) }}
                    <div v-show="record.cdOldExpectStartTime" class="gray">
                      原：
                      {{ $Format(record.cdOldExpectStartTime).slice(0, 10) }} -
                      {{ $Format(record.cdOldExpectEndTime).slice(0, 10) }}
                    </div>
                  </div>
                  <div v-else>
                    {{ $Format(record.cpExpectStartTime).slice(0, 10) }}-
                    {{ $Format(record.cpExpectEndTime).slice(0, 10) }}
                    <div v-show="record.cpOldExpectStartTime" class="gray">
                      原：
                      {{ $Format(record.cpOldExpectStartTime).slice(0, 10) }}-
                      {{ $Format(record.cpOldExpectEndTime).slice(0, 10) }}
                    </div>
                  </div>
                </span>
                <span slot="cdPeople" slot-scope="text, record">
                  <div>{{ text ? text : record.cpPeople }}</div>
                </span>
                <span slot="cdReceive" slot-scope="text, record">
                  <!-- {{ text }} -->
                  <div v-if="text">
                    {{ text + "/" + record.cdPeople }}
                  </div>
                  <div v-if="record.cpReceive">
                    {{ record.cpReceive + "/" + record.cpPeople }}
                  </div>
                </span>
                <span slot="cdDuration" slot-scope="text, record">
                  <!-- {{ record.cdDuration ? record.cdDuration : record.cpDuration }} -->
                  <!-- <br /> -->
                  {{ text }}
                  <br />
                  <!-- {{ record.cpDuration }} -->
                  {{ record.cpDuration }}
                  <!-- <div>{{ text ? $calcDate(Math.floor(text)) : "" }}</div> -->
                </span>

                <span slot="cdCheckOwner" slot-scope="text">
                  <div>{{ text == "Y" ? "是" : text == "Y" ? "否" : "" }}</div>
                </span>
                <!-- 操作 -->
                <span slot="action" slot-scope="text, record" class="action">
                  <!-- <a
                    class="editBtn"
                    type="primary"
                    @click="
                      showDrawer('ScheduleEditShow', '新增', 'add', record)
                    "
                    v-show="!viewShow"
                    >新增</a
                  > -->
                  <a
                    v-if="record.cdPId && !viewShow"
                    class="editBtn"
                    @click="
                      showDrawer('ScheduleEditShow', '修改', 'edit', record)
                    "
                    >修改</a
                  >
                </span>
              </a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="物料排期" force-render>
            <div class="tableHeight">
              <a-table
                ref="table"
                size="small"
                rowKey="cmId"
                :columns="columnsMaterials"
                :data-source="tabledataMaterials"
                :scroll="{ x: 1400, y: 400 }"
                :defaultExpandAllRows="true"
                v-if="tableShow"
                :pagination="false"
              >
                <span slot="cmBcId" slot-scope="text">
                  <div>{{ text ? CompanyListResult(text) : "" }}</div>
                </span>

                <span slot="cmMId" slot-scope="text, record">
                  <div>{{ record.tmaterial.mName }}</div>
                </span>

                <span slot="cmMUnit" slot-scope="text, record">
                  <div>{{ record.tmaterial.mUnit }}</div>
                </span>
                <span slot="cmEarlyReachTime" slot-scope="text, record">
                  <div v-if="text">
                    {{ $Format(text).slice(0, 10) }} -
                    {{ $Format(record.cmLateReachTime).slice(0, 10) }}
                  </div>
                </span>

                <span slot="cmEarlySendTime" slot-scope="text, record">
                  <div v-if="text">
                    {{ $Format(text).slice(0, 10) }} -
                    {{ $Format(record.cmLateSendTime).slice(0, 10) }}
                  </div>
                </span>

                <span slot="cmEarlyOrderTime" slot-scope="text, record">
                  <div v-if="text">
                    {{ $Format(text).slice(0, 10) }} -
                    {{ $Format(record.cmLateOrderTime).slice(0, 10) }}
                  </div>
                </span>

                <span slot="cmMState" slot-scope="text">
                  <div>{{ text ? MaterialsStateList[text] : "" }}</div>
                </span>
                <!-- 操作 -->
                <span slot="action" slot-scope="text, record" class="action">
                  <a
                    v-if="!viewShow"
                    class="editBtn"
                    @click="
                      showDrawer(
                        'ScheduleMaterialsEditShow',
                        '修改',
                        'edit',
                        record
                      )
                    "
                    >修改</a
                  >
                </span>
              </a-table>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>

      <div class="padding_bottom">
        <div v-if="viewShow">
          <a-button type="primary" @click="showEdit()">编辑</a-button>
          <a-button @click="cancellClick()" style="margin-left: 20px"
            >返回</a-button
          >
        </div>
        <div v-if="!viewShow">
          <a-button type="primary" @click="submitClick()">提交</a-button>

          <a-button @click="cancellClick()" style="margin-left: 20px"
            >取消</a-button
          >
        </div>
      </div>
    </div>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="true"
    >
      <ScheduleEdit
        @onClose="onClose"
        :record="record"
        :type="recordType"
        ref="ScheduleEdit"
        v-if="ScheduleEditShow"
        @tabledataChange="tabledataChange"
      />
      <ScheduleMaterialsEdit
        @onClose="onClose"
        :record="record"
        :type="recordType"
        ref="ScheduleMaterialsEdit"
        v-if="ScheduleMaterialsEditShow"
        @tabledataChange="tabledataMaterialsChange"
      />
    </a-drawer>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { STable, SSearch, ColumnsSet } from "@/components";
import { GetQuotationList } from "@/api/apiJF/quotation";
import {
  UpdateWorkSite,
  AddWorkSite,
  GetWorkSitePlan,
} from "@/api/apiJF/schedule";
import { GetWorkProceList, GetProjectList } from "@/api/apiJF/template";
import { GetQuotationDetailList } from "@/api/apiJF/quotation";
// import QuotationEdit from "./QuotationEdit.vue";
import ScheduleEdit from "./ScheduleEdit.vue";
import ScheduleMaterialsEdit from "./ScheduleMaterialsEdit.vue";
export default {
  name: "BuildSchedule",
  components: {
    ScheduleEdit,
    STable,
    ScheduleMaterialsEdit,
  },
  data() {
    return {
      viewShow: true, //是否显示详情
      queryParam: {},
      DrawerTitle: "",
      GetCompanyList: {},
      CompanyListid: [],
      CompanyList: {},
      columns: [
        {
          title: "施工工序",
          dataIndex: "cpWpId",
          key: "cpWpId",
          width: "100px",
          // align: "left",
          scopedSlots: { customRender: "cpWpId" },
        },
        {
          title: "施工项",
          dataIndex: "cdPId",
          key: "cdPId",
          // width: "100px",
          // align: "left",
          scopedSlots: { customRender: "cdPId" },
        },
        {
          title: "公司名称",
          dataIndex: "cdBcId",
          key: "cdBcId",
          width: "100px",
          scopedSlots: { customRender: "cdBcId" },
        },
        {
          title: "施工量",
          dataIndex: "cdQuantity",
          key: "cdQuantity",
          width: "100px",
        },
        {
          title: "工程计量单位",
          dataIndex: "cdUnit",
          key: "cdUnit",
          width: "100px",
        },
        {
          title: "施工时长(小时)",
          dataIndex: "cdDuration",
          key: "cdDuration",
          width: "100px",
          scopedSlots: { customRender: "cdDuration" },
        },
        {
          title: "起止日期",
          dataIndex: "cdExpectStartTime",
          key: "cdExpectStartTime",
          width: "200px",
          scopedSlots: { customRender: "cdExpectStartTime" },
        },
        {
          title: "施工人数",
          dataIndex: "cdPeople",
          key: "cdPeople",
          width: "100px",
          scopedSlots: { customRender: "cdPeople" },
        },
        {
          title: "业主验收",
          dataIndex: "cdCheckOwner",
          key: "cdCheckOwner",
          width: "100px",
          scopedSlots: { customRender: "cdCheckOwner" },
        },
        {
          title: "接单状态",
          dataIndex: "cdReceive",
          key: "cdReceive",
          width: "100px",
          scopedSlots: { customRender: "cdReceive" },
        },
      ],
      columnsMaterials: [
        {
          title: "物料",
          dataIndex: "cmMId",
          key: "cmMId",
          // width: "100px",
          // align: "left",
          scopedSlots: { customRender: "cmMId" },
        },
        // {
        //   title: "公司名称",
        //   dataIndex: "cmBcId",
        //   key: "cmBcId",
        //   width: "100px",
        //   scopedSlots: { customRender: "cmBcId" },
        // },
        {
          title: "单位",
          dataIndex: "cmMUnit",
          key: "cmMUnit",
          width: "80px",
          // align: "left",
          scopedSlots: { customRender: "cmMUnit" },
        },

        {
          title: "数量",
          dataIndex: "cmQuantity",
          key: "cmQuantity",
          width: "100px",
        },
        {
          title: "供应商",
          dataIndex: "cmSName",
          key: "cmSName",
          width: "100px",
          scopedSlots: { customRender: "cmSName" },
        },
        {
          title: "需要到达日期",
          dataIndex: "cmEarlyReachTime",
          key: "cmEarlyReachTime",
          width: "200px",
          scopedSlots: { customRender: "cmEarlyReachTime" },
        },
        {
          title: "材料发货时间",
          dataIndex: "cmEarlySendTime",
          key: "cmEarlySendTime",
          width: "200px",
          scopedSlots: { customRender: "cmEarlySendTime" },
        },
        {
          title: "应下单时间",
          dataIndex: "cmEarlyOrderTime",
          key: "cmEarlyOrderTime",
          width: "200px",
          scopedSlots: { customRender: "cmEarlyOrderTime" },
        },
        {
          title: "物料下单情况",
          dataIndex: "cmMState",
          key: "cmMState",
          width: "100px",
          scopedSlots: { customRender: "cmMState" },
        },
      ],
      WorkProceList: [], //施工工序
      ProjectList: [], //施工项
      ExtraChargeList: [], //额外收费项
      ProjectMaterialListMain: [], //主材物料信息
      ProjectMaterialListMainLis: [], //主材物料信息id
      QuotationList: [], //报价列表
      visibleDrawer: false,
      form: {
        wsBcId: "",
        wsMSelector: "1", //材料选择顺序
        wsQId: "",
        wsExpectTime: "", //预计开工时间
      },
      tabledata: [],
      tabledataMaterials: [],
      record: {
        qdBcId: "",
      },
      recordType: "",
      isShowcancellBtn: true,
      tableShow: false,
      ScheduleEditShow: false,
      ScheduleMaterialsEditShow: false,
      wsMSelectorList: [
        { id: "1", name: "成本" },
        { id: "2", name: "质量" },
        { id: "3", name: "时效" },
      ],
      //  物料状态
      MaterialsStateList: [
        "",
        "未下单",
        "已下单",
        "待发货",
        "已发货",
        "待验收",
        "已验收",
      ],

      getProjectList: [],
      valueFormat: "X",
      PlanQueryParam: {
        wsQId: "",
        wsExpectTime: "",
        wsMSelector: "",
        wsBcId: "",
        wsId: "",
        type: "",
      },
      isShowwsLatestBtn: true,
    };
  },
  created() {
    this.GetCompaniesId();
    // console.log("this.$route.query", this.$route.query);
    // 路由传参，循环赋值
    let query = JSON.parse(this.$route.query.record);
    console.log("query.record", query);
    if (query) {
      if (Object.keys(query).length != 0) {
        for (let el in query) {
          this.form[el] = query[el];
        }

        // 测试材料优先选择顺序
        // let wsMSelector = "1,2,3";
        if (this.form.wsMSelector) {
          this.form.wsMSelector = this.form.wsMSelector
            ? this.form.wsMSelector.split(",")
            : "";
        }
      }
    }
    // console.log("this.form", this.form);
    if (this.$route.query.type == "edit") {
      this.$route.meta.title = "排期详情";
      this.loadData();
    } else {
      this.$route.meta.title = "新增排期";
      this.tabledata = [];
      this.viewShow = false;
      this.columns.push({
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        align: "center",
        fixed: "right",
      });
      this.columnsMaterials.push({
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        align: "center",
        fixed: "right",
      });
    }
    this.form.wsExpectTime = this.form.wsExpectTime
      ? this.form.wsExpectTime + ""
      : "";
    // console.log("  this.form", this.form);
    setTimeout(() => {
      this.tableShow = true;
    }, 300);
  },
  mounted() {
    // console.log("获取角色信息", this.$store.state);
  },
  computed: {},
  watch: {
    "form.wsBcId": {
      handler(newValue) {
        if (newValue) {
          // 获取施工工序
          this.GetWorkProceList(newValue);
          // 获取施工项
          this.GetProjectList(newValue);
          //   获取报价列表
          this.GetQuotationList(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听表格数据
    tabledata: {
      handler(newValue) {},
      deep: true,
      immediate: true,
    },
    "form.wsMSelector": {
      handler(newValue) {
        // this.GetWorkSitePlan();
      },
    },
    "form.wsExpectTime": {
      handler(newValue) {
        // this.GetWorkSitePlan();
      },
    },
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
          this.GetCompanyList = res.data;
        })
        .then(() => {
          this.queryParam.qdBcIds = this.CompanyListid.toString();
        })
        .then(() => {
          // if (this.$route.query.type == "edit") {
          //   this.loadData();
          // }
        });
    },
    // 根据分公司id查询分公司名称
    CompanyListResult(a) {
      return this.CompanyList[a];
    },
    loadData() {
      let record = JSON.parse(this.$route.query.record);
      this.tabledata = record.tconstructionPlans;
      this.tabledataMaterials = record.tconstructionMaterials;
    },

    // 获取施工工序
    GetWorkProceList(wpBcIds) {
      let queryParam = {
        wpBcIds: wpBcIds ? wpBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["wpBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkProceList(params)
        .then((res) => {
          // console.log("施工工序", res.data);
          res.data.forEach((el) => {
            this.WorkProceList[el.wpId] = el.wpName;
          });
        })
        .then(() => {});
    },
    // 根据施工工序id查询施工工序名称
    WorkProceResult(a) {
      return this.WorkProceList[a];
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        this.getProjectList = res.data;
        res.data.forEach((el) => {
          this.ProjectList[el.pid] = el.pname;
          el.children.forEach((item) => {
            this.ProjectList[item.pid] = item.pname;
          });
        });
        // console.log("施工项", res.data);
      });
    },
    // 根据施工项id查询施工工序名称
    ProjectListResult(a) {
      return this.ProjectList[a];
    },
    // 根据分公司获取报价列表
    GetQuotationList(qBcIds) {
      let queryParam = {};
      let orderParam = [];
      if (this.$route.query.type == "edit") {
        queryParam = {
          qBcIds: qBcIds ? qBcIds : this.CompanyListid.toString(),
        };
        orderParam = ["qBcIds"];
      } else {
        queryParam = {
          qBcIds: qBcIds ? qBcIds : this.CompanyListid.toString(),
          filter: "noWorkSite",
        };
        orderParam = ["qBcIds", "filter"];
      }

      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetQuotationList(params).then((res) => {
        // console.log("报价列表", res.data);
        this.QuotationList = res.data;
      });
    },
    // 根据数据获取新的工地排期
    GetWorkSitePlan() {
      let query = JSON.parse(this.$route.query.record);
      console.log("query", query);
      if (this.$route.query.type == "edit") {
        // this.PlanQueryParam = {
        //   wsQId: query.wsQId,
        //   wsExpectTime: this.form.wsExpectTime,
        //   wsMSelector: this.form.wsMSelector.toString(),
        //   wsBcId: this.form.wsBcId,
        //   wsId: query.wsId,
        // };
        this.PlanQueryParam.wsQId = this.form.wsQId;
        this.PlanQueryParam.wsExpectTime = this.form.wsExpectTime;
        this.PlanQueryParam.wsMSelector = this.form.wsMSelector.toString();
        this.PlanQueryParam.wsBcId = this.form.wsBcId;
        this.PlanQueryParam.wsId = query.wsId;
      } else {
        this.PlanQueryParam = {
          wsQId: this.form.wsQId,
          wsExpectTime: this.form.wsExpectTime,
          wsMSelector: this.form.wsMSelector.toString(),
          wsBcId: this.form.wsBcId,
        };
      }
      let newObject = {};
      for (let el in this.PlanQueryParam) {
        if (this.PlanQueryParam[el] != "" && this.PlanQueryParam[el] != null) {
          newObject[el] = this.PlanQueryParam[el];
        }
      }
      console.log("newObject", newObject);
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, this.PlanQueryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWorkSitePlan(params).then((res) => {
        // console.log("新的工地排期", res.data);
        this.tabledata = res.data.tconstructionPlans;
        this.tabledataMaterials = res.data.tconstructionMaterials;
        this.form.wsLatest = res.data.wsLatest;
        if (this.$route.query.type == "add" && res.code == 200) {
          let form = {};
          let query = res.data;
          console.log("query", query);
          for (let el in query) {
            form[el] = query[el];
          }
          // this.newForm = form;
          // // 测试材料优先选择顺序
          // let wsMSelector = "1,2,3";
          if (form.wsMSelector) {
            form.wsMSelector = form.wsMSelector.split(",");
          }
          this.form = form;

          this.form.wsExpectTime = this.form.wsExpectTime + "";
          // this.form.wsName = res.data.wsName;
          // this.form.wsBcId = res.data.wsBcId;
          // this.tabledata = res.data.tconstructionPlans;
          // this.tabledataMaterials = res.data.tconstructionMaterials;
        }
        if (res.code == 200) {
          this.isShowwsLatestBtn = false;
        }
      });
    },
    // 点击编辑，  显示编辑页面
    showEdit() {
      this.$route.meta.title = "排期编辑";
      this.viewShow = false;
      this.columns.push({
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        align: "center",
        fixed: "right",
      });
      this.columnsMaterials.push({
        title: "操作",
        key: "action",
        scopedSlots: { customRender: "action" },
        width: "200px",
        align: "center",
        fixed: "right",
      });
    },
    afterVisibleChange(val) {},
    // 展开抽屉
    showDrawer(name, title, type, record) {
      // console.log("showDrawer_record", record, this.form);
      this.visibleDrawer = true;
      this.DrawerTitle = title;
      this.ScheduleEditShow = false;
      this.ScheduleMaterialsEditShow = false;
      this[name] = true;
      if (record) {
        this.record = record;
      }
      this.recordType = type;
    },

    onClose() {
      this.visibleDrawer = false;
      this.ScheduleEditShow = false;
      this.ScheduleMaterialsEditShow = false;
    },
    // 表格编辑
    tabledataChange(event) {
      // console.log(
      //   "this.$refs.ScheduleEdit.emitForm",
      //   this.$refs.ScheduleEdit.emitForm
      // );
      //   console.log("this.tabledata", this.tabledata);
      let emitForm = this.$refs.ScheduleEdit.editForm;

      // console.log("tabledataChange", emitForm);
      if (this.tabledata.length == 0) {
        this.tabledata.push(emitForm);
        this.onClose();
      } else {
        // 下标大于-1   证明存在
        let findIndex = this.tabledata.findIndex((item) => {
          return item.cpWpId == emitForm.cpWpId;
        });
        if (findIndex > -1) {
          this.tabledata.forEach((el, index) => {
            if (el.cpWpId == emitForm.cpWpId) {
              let pdpIdindex = el.children.findIndex((item) => {
                return item.cdPId == emitForm.children[0].cdPId;
              });
              // 如果是编辑，直接赋值
              if (this.recordType == "edit") {
                el.children.forEach((elChildren, index) => {
                  if (elChildren.cdPId == emitForm.children[0].cdPId) {
                    el.children[index] = emitForm.children[0];
                  } else {
                  }
                });
                this.onClose();
              } else {
                // 如果是添加，判断是否重复添加
                if (pdpIdindex == -1) {
                  el.children.push(emitForm.children[0]);
                  this.onClose();
                } else {
                  this.$message.error("施工项重复设置");
                }
              }
            } else {
              // this.$message.error("施工项重复设置");
            }
          });
        } else {
          this.tabledata.push(emitForm);
          this.onClose();
        }
      }

      setTimeout(() => {
        let tabledata = [...this.tabledata];
        tabledata.forEach((el) => {
          let cpDuration = 0; //所需时间
          let cpPeople = 0; //施工人数
          let dateList = []; //施工日期数组
          el.children.forEach((item) => {
            item.cdDuration = Math.round(item.cdDuration * 100) / 100;
            cpDuration = cpDuration * 1 + item.cdDuration * 1;
            cpPeople = cpPeople * 1 + item.cdPeople * 1;
            dateList.push(item.cdExpectStartTime, item.cdExpectEndTime);
          });
          // el.cpDuration = cpDuration;

          el.cpDuration = Math.round(cpDuration * 100) / 100;
          el.cpPeople = cpPeople;
          let dateList1 = dateList.filter((el) => el != null);
          if (dateList1.length > 0) {
            let dateListSort = dateList1.sort((a, b) => a - b);
            el.cpExpectStartTime = dateListSort[0];
            el.cpExpectEndTime = dateListSort[dateListSort.length - 1];
          }
          //   console.log("el.cpDuration", el.cpDuration);
        });
        this.tabledata = tabledata;
      }, 10);
      this.tabledataDateChange(emitForm);
    },
    // 物料排期表格修改
    tabledataMaterialsChange() {
      let emitForm = this.$refs.ScheduleMaterialsEdit.editForm;
      // console.log("物料排期表格修改", emitForm);
      let tabledataMaterials = this.tabledataMaterials;
      tabledataMaterials.forEach((el) => {
        if (el.cmMId == emitForm.cmMId) {
          el.cmEarlyReachTime = emitForm.cmEarlyReachTime;
          el.cmLateReachTime = emitForm.cmLateReachTime;
        }
      });
      this.tabledataMaterials = tabledataMaterials;
      this.onClose();
    },
    // 施工排期里的施工项修改，物料排期施工项变化
    tabledataDateChange(value) {
      // console.log("DateChange", value, value.children[0]);
      let tabledataMaterials = this.tabledataMaterials;
      tabledataMaterials.forEach((el) => {
        // console.log("tabledataMaterials---el", el);

        if (el.cmWpId == value.cpWpId && el.cmPId == value.children[0].cdPId) {
          let startTime = value.children[0].cdExpectStartTime;
          console.log("起始日期", startTime);
          // 需要到达日期
          el.cmEarlyReachTime = startTime - 2 * 24 * 60 * 60;
          el.cmLateReachTime = startTime;
          // 材料发货日期
          el.cmEarlySendTime = el.cmEarlyReachTime - el.cmMDeliTime * 3600;
          el.cmLateSendTime = el.cmLateReachTime - el.cmMDeliTime * 3600;
          // 应下单日期
          el.cmEarlyOrderTime = el.cmEarlySendTime - el.cmMPreTime * 3600;
          el.cmLateOrderTime = el.cmLateSendTime - el.cmMPreTime * 3600;

          console.log("准备时长", el.cmMPreTime);
          console.log("发货时长", el.cmMDeliTime);
        }
      });
      this.tabledataMaterials = tabledataMaterials;
    },

    // 提交按钮
    submitClick() {
      console.log("form", this.form);
      if (this.$route.query.type == "edit") {
        console.log("编辑");
        this.UpdateSchedule();
      } else {
        // 新建基本信息;
        console.log("新增");
        this.AddSchedule();
      }
    },
    // 新建基本信息
    AddSchedule() {
      let params = this.form;
      // console.log("this.form", this.form);
      params.tconstructionPlans = this.tabledata;
      params.tconstructionMaterials = this.tabledataMaterials;

      params.wsMSelector = this.form.wsMSelector.toString();
      AddWorkSite(params).then((res) => {
        // console.log(res.data);
        // this.AddScheduleDetailList(res.data);
        if (res.code == 200) {
          this.$message.success("新建排期成功");
          this.$router.push({
            path: "/ScheduleList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改基本信息
    UpdateSchedule() {
      let params = this.form;
      params.tconstructionPlans = this.tabledata;
      params.tconstructionMaterials = this.tabledataMaterials;
      params.wsMSelector = this.form.wsMSelector.toString();
      // console.log(params, this.tabledata);
      UpdateWorkSite(params).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("修改排期成功");
          this.$router.push({
            path: "/ScheduleList",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳转到排期列表页面
    cancellClick() {
      this.columns.filter((el) => {
        return el.key == "action";
      });
      this.columnsMaterials.filter((el) => {
        return el.key == "action";
      });
      this.$router.push({
        path: "/ScheduleList",
      });
    },
    //报价改变
    handleChangewsQId() {
      this.GetWorkSitePlan();
    },
    //材料优先选择顺序改变
    handleChangewsMSelector(e) {
      // console.log("e", e, this.form);
      this.GetWorkSitePlan();
    },
    //预计开始时间改变
    handleChangewsExpectTime() {
      this.GetWorkSitePlan();
    },
    // 是否同步报价信息
    isChangeInfo() {
      this.PlanQueryParam.type = "refresh";
      this.GetWorkSitePlan();
    },
  },
};
</script>

<style lang="less" scoped>
.infoTit {
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.basicInfo {
  text-align: left;

  .displayFlex {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.editable-row-operations a {
  margin-right: 8px;
}
.editBtn {
  margin-right: 20px;
}
.delName {
  color: red;
}
.addBtn {
  text-align: left;
  margin-top: 20px;
}
.bottom {
  //   margin: 10px 50px;
  .displayFlex {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .ant-table-tbody > tr {
  height: 90px;
}
/deep/.ant-checkbox-group {
  width: 80%;
  text-align: left;
}

/deep/.ant-input[disabled],
.ant-input-disabled,
.ant-select-disabled {
  color: rgba(0, 0, 0, 1);
}
.padding_bottom {
  padding-bottom: 100px;
}

.basicInfo_edit {
  /deep/.ant-row {
    margin: 10px 50px 10px 0;
  }
}
.info_inner {
  text-align: left;
}
.wsLatestinfo {
  text-align: left;
  margin: 10px 0;
  display: flex;
  align-items: center;
  .wsLatestBtn {
    margin-right: 20px;
    // padding: 10px;
    height: 45px;
  }
}
.gray {
  color: rgba(128, 128, 128, 0.568);
}
</style>
